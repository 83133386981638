import Vue from 'vue'
import App from './App'
import store from './store'
import router from './router'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css'
import * as echarts from 'echarts'

import i18n from '@/lang'
Vue.use(Element, {
  i18n: (key, value) => i18n.t(key, value)
})
import './plugins'
import '@/layouts/export'
//引用富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
 //引入富文本css
// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

// import i18n from '@/lang'

//富文本编辑器添加实例
Vue.use(VueQuillEditor, /* { default global options } */)
/**
 * @description 生产环境默认都使用mock，如果正式用于生产环境时，记得去掉
 */

// if (process.env.NODE_ENV === 'production') {
//   const { mockXHR } = require('@/utils/static')
//   mockXHR()
// }
// 小数点后2位
Vue.prototype.$geShi = function(ob) {
  ob = ob.replace(/[^\d.]/g, '') // 清除"数字"和"."以外的字符
  ob = ob.replace(/^\./g, '') // 验证第一个字符是数字而不是.
  ob = ob.replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
  ob = ob.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
  ob = ob.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3') // 只能输入两个小数
  if (ob.indexOf('.') < 0 && ob !== '') { // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的数字
    ob = parseFloat(ob)
  }
  return ob
}
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
new Vue({
  el: '#vue-admin-beautiful',
  router,
  store,
  i18n,
  render: (h) => h(App),
})

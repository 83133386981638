import Vue from 'vue'
import VueI18n from 'vue-i18n' // 引入国际化插件包
import elementEnLocale from 'element-ui/lib/locale/lang/en.js' // element-ui 英文包
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'// element-ui 中文包
import elLocale from 'element-ui/lib/locale'
import customEN from './en'  // 自定义英语包
import customZH from './zh'  // 自定义中文包
Vue.use(VueI18n) // 全局注册国际化包

const messages = {
    en: {
        ...customEN,
        ...elementEnLocale
    },
    zh: {
        ...customZH ,
        ...elementZhLocale
    }
}
console.log(messages)
export function getLanguage() {
    const chooseLanguage = localStorage.getItem('lang') || 'zh'
    if (chooseLanguage) return chooseLanguage

    // 如果没有选择语言
    const language = (navigator.language || navigator.browserLanguage).toLowerCase()
    const locales = Object.keys(messages)
    for (const locale of locales) {
        if (language.indexOf(locale) > -1) {
            return locale
        }
    }
    return 'zh' // 默认语言
}
const i18n = new VueI18n({
    locale: getLanguage(),
    messages
})
var Lang = getLanguage()
elLocale.use(messages[Lang])

export default i18n

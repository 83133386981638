/**
 * @description router全局配置，如有必要可分文件抽离，其中asyncRoutes只有在intelligence模式下才会用到，vip文档中已提供路由的基础图标与小清新图标的配置方案，请仔细阅读
 */

import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layouts'
import EmptyLayout from '@/layouts/EmptyLayout'
import { publicPath, routerMode } from '@/config'

Vue.use(VueRouter)
export const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true,
  },
  // {
  //   path: '/listPage',
  //   component: () => import('@/views/listPage/index'),
  //   hidden: true,
  // },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404'),
    hidden: true,
  },
]

export const asyncRoutes = [
  {
    path: '/',
    component: Layout,
    redirect: 'index',
    children: [
      {
        path: 'index',
        name: 'Index',
        component: () => import('@/views/index/index'),
        meta: {
          title: '首页',
          icon: 'el-icon-s-home',
          affix: true,
        },
      },
    ],
  },

  // {
  //   path: '/userManagement',
  //   component: Layout,
  //   redirect: 'userManagement',
  //   children: [{
  //     path: 'userManagement',
  //     name: 'userManagement',
  //     component: () => import('@/views/userManagement/index'),
  //     meta: {
  //       title: '用户管理',
  //       icon: 'user-cog',
  //       affix: true,
  //     },
  //   }, ],
  // },

  // {
  //   path: '/parcelManagement',
  //   component: Layout,
  //   redirect: 'parcelManagement',
  //   name: 'parcelManagement',
  //   alwaysShow: true,
  //   meta: {
  //     title: '包裹管理',
  //     icon: 'folder-open',
  //   },
  //   children: [{
  //       path: 'manageParcelKorea',
  //       name: 'manageParcelKorea',
  //       component: () => import('@/views/parcelManagement/manageParcelKorea'),
  //       meta: {
  //         title: '韩国包裹管理',
  //       },
  //     },
  //     {
  //       path: 'manageParcelJapan',
  //       name: 'manageParcelJapan',
  //       component: () => import('@/views/parcelManagement/manageParcelJapan'),
  //       meta: {
  //         title: '日本包裹管理',
  //       },
  //     },
  //     {
  //       path: 'manageParcel_Tl',
  //       name: 'manageParcel_Tl',
  //       component: () => import('@/views/parcelManagement/manageParcel_Tl'),
  //       meta: {
  //         title: '待认领包裹',
  //       },
  //     },
  //     {
  //       path: 'manageBuyPay',
  //       name: 'manageBuyPay',
  //       component: () => import('@/views/parcelManagement/manageBuyPay/index'),
  //       meta: {
  //         title: '韩区代买管理',
  //       },
  //     },
  //     {
  //       path: 'manageBuyPayJ',
  //       name: 'manageBuyPayJ',
  //       component: () => import('@/views/parcelManagement/manageBuyPay/index_J'),
  //       meta: {
  //         title: '日区代买管理',
  //       },
  //     },
  //     {
  //       path: 'managePayBuy',
  //       name: 'managePayBuy',
  //       component: () => import('@/views/parcelManagement/managePayBuy'),
  //       meta: {
  //         title: '代付管理',
  //       },
  //     },
  //     {
  //       path: 'manageCommodityList',
  //       name: 'manageCommodityList',
  //       component: () => import('@/views/parcelManagement/manageCommodityList'),
  //       meta: {
  //         title: '商品订单',
  //       },
  //     },
  //     {
  //       path: 'Premium_products',
  //       name: 'Premium_products',
  //       component: () => import('@/views/parcelManagement/manageCommodityList/index_buJia'),
  //       meta: {
  //         title: '补价商品订单',
  //       },
  //     },
  //     // 代买代付入库处理
  //     {
  //       path: 'inStorage',
  //       name: 'inStorage',
  //       component: () => import('@/views/parcelManagement/inStorage'),
  //       hidden: true,
  //       meta: {
  //         title: '代买代付入库处理',
  //       },
  //     },
  //     {
  //       path: 'manageBrand',
  //       name: 'manageBrand',
  //       component: () => import('@/views/parcelManagement/manageBrand/index'),
  //       meta: {
  //         title: '品牌管理',
  //       },
  //     },
  //     {
  //       path: 'manageTypes',
  //       name: 'manageTypes',
  //       component: () => import('@/views/parcelManagement/manageTypes'),
  //       meta: {
  //         title: '类型管理',
  //       },
  //     },
  //     {
  //       path: 'managePostage',
  //       name: 'managePostage',
  //       component: () => import('@/views/parcelManagement/managePostage'),
  //       meta: {
  //         title: '邮费模版管理',
  //         permissions: ['admin', 'editor'],
  //       },
  //     },
  //     {
  //       path: 'edit',
  //       name: 'edit',
  //       component: () => import('@/views/parcelManagement/managePostage/edit'),
  //       meta: {
  //         title: '邮费模版编辑',
  //         permissions: ['admin', 'editor'],
  //       },
  //       hidden: true,
  //     },

  //     {
  //       path: 'add',
  //       name: 'add',
  //       component: () => import('@/views/parcelManagement/managePostage/add'),
  //       meta: {
  //         title: '邮费模版添加',
  //         permissions: ['admin', 'editor'],
  //       },
  //       hidden: true,
  //     },

  //   ],

  // },

  // {
  //   path: '/regulate',
  //   component: Layout,
  //   redirect: 'regulate',
  //   name: 'regulate',
  //   alwaysShow: true,
  //   meta: {
  //     title: '营销管理',
  //     icon: 'infinity',
  //   },
  //   children: [{
  //       path: 'banner',
  //       name: 'banner',
  //       component: () => import('@/views/regulate/manageBanner'),
  //       meta: {
  //         title: '轮播图管理',
  //         permissions: ['admin', 'editor'],
  //       },
  //     },
  //     {
  //       path: 'manageGoods',
  //       name: 'manageGoods',
  //       component: () => import('@/views/regulate/manageGoods'),
  //       meta: {
  //         title: '商品管理',
  //       },
  //     },
  //     {
  //       path: 'buyTypeList',
  //       name: 'buyTypeList',
  //       component: () => import('@/views/regulate/buyTypeList'),
  //       meta: {
  //         title: '商品分类',
  //       },
  //     },

  //     {
  //       path: 'addedServices',
  //       name: 'addedServices',
  //       component: () => import('@/views/regulate/addedServices'),
  //       meta: {
  //         title: '增值服务',
  //       },
  //     },
  //     {
  //       path: 'manageStore',
  //       name: 'manageStore',
  //       component: () => import('@/views/regulate/manageStore/index'),
  //       meta: {
  //         title: '仓库地址',
  //       },
  //     },

  //     {
  //       path: 'shopLink',
  //       name: 'shopLink',
  //       component: () => import('@/views/regulate/shopLink/shopLink'),
  //       meta: {
  //         title: '韩国电商平台',
  //       },
  //     },
  //     {
  //       path: 'helpCenter',
  //       name: 'helpCenter',
  //       component: () => import('@/views/regulate/helpCenter/helpCenter'),
  //       meta: {
  //         title: '帮助中心',
  //       },
  //     },

  //   ],
  // },

  // {
  //   path: '/payManagement',
  //   component: Layout,
  //   redirect: 'payManagement',
  //   name: 'payManagement',
  //   alwaysShow: true,
  //   meta: {
  //     title: '支付管理',
  //     icon: 'registered',
  //   },

  //   children: [{
  //     path: 'managePay',
  //     name: 'managePay',
  //     component: () => import('@/views/payManagement/managePay'),
  //     meta: {
  //       title: '支付列表',
  //     },
  //   }, ],
  // },
  // {
  //   path: '/zdh',
  //   component: Layout,
  //   redirect: 'zdh',
  //   name: 'zdh',
  //   alwaysShow: true,
  //   meta: {
  //     title: '中到韩物流',
  //     icon: 'plane-departure',
  //   },

  //   children: [{
  //       path: 'manageParcel',
  //       name: 'manageParcel',
  //       component: () => import('@/views/zdh/manageParcel'),
  //       meta: {
  //         title: '包裹列表',
  //       },
  //     },
  //     {
  //       path: 'manageParcel_Tl',
  //       name: 'manageParcel_Tl',
  //       component: () => import('@/views/zdh/manageParcel_Tl'),
  //       meta: {
  //         title: '待认领包裹',
  //       },
  //     },

  //     {
  //       path: 'korPostage',
  //       name: 'korPostageou',
  //       component: () => import('@/views/zdh/korPostage'),
  //       meta: {
  //         title: '邮费模版',
  //       },
  //     },
  //     {
  //       path: 'Proxy',
  //       name: 'Proxy',
  //       component: () => import('@/views/zdh/Proxy'),
  //       meta: {
  //         title: '申请代理',
  //       },
  //     }, {
  //       path: 'managePay',
  //       name: 'managePay',
  //       component: () => import('@/views/zdh/managePay'),
  //       meta: {
  //         title: '支付管理',
  //       },
  //     },
  //     {
  //         path: 'userManagement_user',
  //         name: 'userManagement_user',
  //         component: () => import('@/views/userManagement/index_ZtoH'),
  //         meta: {
  //           title: '用户管理',
  //           icon: 'user-cog',
  //           affix: true,
  //         },
  //       }
  //   ],
  // },

  // {
  //   path: '/seeting',
  //   component: Layout,
  //   redirect: 'seeting',
  //   meta: { title: '系统管理', icon: 'user-cog', affix: true, },
  //   children: [
  //       {
  //         path: 'editionlist',
  //         name: 'editionlist',
  //         component: () => import('@/views/seeting/editionlist'),
  //         meta: {
  //           title: '版本管理',
  //           icon: 'user-cog',
  //           affix: true,
  //         },
  //       },
  //     {
  //       path: 'countryList',
  //       name: 'countryList',
  //       component: () => import('@/views/seeting/countryList'),
  //       meta: {
  //         title: '国家管理',
  //         icon: 'user-cog',
  //         affix: true,
  //       },
  //     },
  //     {
  //       path: 'SystemAccount',
  //       name: 'SystemAccount',
  //       component: () => import('@/views/seeting/SystemAccount'),
  //       meta: {
  //         title: '系统用户',
  //         icon: 'user-cog',
  //         affix: true,
  //       },
  //     },
  //     {
  //       path: 'SidebarSettings',
  //       name: 'SidebarSettings',
  //       component: () => import('@/views/seeting/SidebarSettings'),
  //       meta: {
  //         title: '权限组',
  //         icon: 'user-cog',
  //         affix: true,
  //       },
  //     },
  //     {
  //       path: 'sildBarList',
  //       name: 'sildBarList',
  //       component: () => import('@/views/seeting/sildBarList'),
  //       meta: {
  //         title: '侧边栏管理',
  //         icon: 'user-cog',
  //         affix: true,
  //       },
  //     },
  //   ],
  // },

  {
    path: '/error',
    component: EmptyLayout,
    redirect: 'noRedirect',
    name: 'Error',
    hidden: true,
    meta: {
      title: '错误页',
      icon: 'bug',
    },
    children: [
      {
        path: '404',
        name: 'Error404',
        component: () => import('@/views/404'),
        meta: {
          title: '404',
        },
      },
    ],
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true,
  },
]

const router = new VueRouter({
  base: publicPath,
  mode: routerMode,
  scrollBehavior: () => ({
    y: 0,
  }),
  routes: constantRoutes,
})
//注释的地方是允许路由重复点击，如果觉得框架路由跳转规范太过严格可选择放开
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}

export function resetRouter() {
  router.matcher = new VueRouter({
    base: publicPath,
    mode: routerMode,
    scrollBehavior: () => ({
      y: 0,
    }),
    routes: constantRoutes,
  }).matcher
}

export default router
